'use client';

import React, { useCallback, useEffect, useMemo, useRef } from 'react';

// import Mark from 'mark.js';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';

const MARK_TIMEOUT = 500;

const SearchHighlighterProvider: React.FC = () => {
  const markInstance = useRef<any>();
  const p = useSearchParams();
  const srch = useMemo(() => p?.get('q'), [p]);
  const type = useMemo(() => p?.get('tp'), [p]);

  const handleHl = useCallback(async () => {
    if (!srch) return;

    const mainEl = document.querySelector('main');
    if (mainEl) {
      const hanlder = () => {
        document.body.classList.remove('show-hl');
      };

      const Mark = (await import('mark.js')).default;

      markInstance.current = new Mark(mainEl);
      markInstance.current.unmark({
        done: () => {
          window.removeEventListener('scroll', hanlder);
          window.addEventListener('scroll', hanlder);
          markInstance.current?.mark(srch, {
            caseSensitive: false,
            separateWordSearch: false,
            acrossElements: true
          });
          setTimeout(() => {
            document.body.classList.add('show-hl');
          }, MARK_TIMEOUT);
        }
      });
    }
  }, [srch]);

  const handleScroll = useCallback(() => {
    const markEl = document.querySelector('mark');

    if (markEl) {
      if (type === 'team') {
        const wrapEl = markEl.closest('.cms-team__box');

        if (wrapEl) {
          let top = wrapEl.getBoundingClientRect().top - 124;
          if (top < 0) {
            top = 0;
          }
          window.scrollTo({ top: top });
        } else {
          let top = markEl.getBoundingClientRect().top - 100;
          if (top < 0) {
            top = 0;
          }
          window.scrollTo({ top: top });
        }

        return;
      } else if (type === 'partner') {
        const wrapEl = markEl.closest('.cms-partners__item');

        if (wrapEl) {
          let top = wrapEl.getBoundingClientRect().top;
          if (top < 0) {
            top = 0;
          }
          window.scrollTo({ top: top });
        } else {
          let top = markEl.getBoundingClientRect().top - 80;
          if (top < 0) {
            top = 0;
          }
          window.scrollTo({ top: top });
        }
      } else {
        let top = markEl.getBoundingClientRect().top - 100;
        if (top < 0) {
          top = 0;
        }
        window.scrollTo({ top: top });
      }
    }
  }, [type]);

  useEffect(() => {
    if (!srch) return;

    handleHl();
    setTimeout(() => handleScroll(), 200);
  }, [handleHl, handleScroll, srch]);

  if (!srch) return null;

  return (
    <Script
      id="srch-hl"
      async
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        window.__hl__ = function() {
          var params = new URL(document.location).searchParams;
          var search = params.get("q");
          if (search && search !== "") {
            var script = document.createElement('script');
            script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/mark.js/8.11.1/mark.min.js');
            document.head.append(script);
            script.onload =function(){
              var srch = decodeURIComponent(search);
              var instance = new Mark(document.querySelector('main'));
              console.log(instance)
              instance.mark(srch, {caseSensitive: false, separateWordSearch: false});
              document.body.classList.add('show-hl');
            }
          }
        }`
      }}
    />
  );
};

export { SearchHighlighterProvider };
